// Border Radius
.border-radius(@radius) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

// Transition
.transition(@transition) {
  -webkit-transition: @transition;
  -moz-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}

// transform rotate
.rotate(@degrees) {
  -webkit-transform: rotate(@degrees);
  -ms-transform: rotate(@degrees); // IE9 only
  -o-transform: rotate(@degrees);
  transform: rotate(@degrees);
}

// transform scale
.scale(@ratio) {
  -webkit-transform: scale(@ratio);
  -ms-transform: scale(@ratio); // IE9 only
  -o-transform: scale(@ratio);
  transform: scale(@ratio);
}

.scaleX(@ratio) {
  -webkit-transform: scaleX(@ratio);
  -ms-transform: scaleX(@ratio); // IE9 only
  -o-transform: scaleX(@ratio);
  transform: scaleX(@ratio);
}

.scaleY(@ratio) {
  -webkit-transform: scaleY(@ratio);
  -ms-transform: scaleY(@ratio); // IE9 only
  -o-transform: scaleY(@ratio);
  transform: scaleY(@ratio);
}

.translateY(@ration) {
  -webkit-transform: translateY(@ration);
  -moz-transform: translateY(@ration);
  -ms-transform: translateY(@ration);
  -o-transform: translateY(@ration);
  transform: translateY(@ration);
}

.translateX(@ration) {
  -webkit-transform: translateX(@ration);
  -moz-transform: translateX(@ration);
  -ms-transform: translateX(@ration);
  -o-transform: translateX(@ration);
  transform: translateX(@ration);
}